import './Instructors.css';
import PopupButton from './PopupButton';
import Whatsapp from './Whatsapp';
import Popup from './Popup';
import {useState} from 'react';
import Popupform from './Popupform';
import Smolyaninov from '../images/smolyaninov.jpeg';
import Myagkov from '../images/myagkov.jpg';
import Tumanov from '../images/tumanov.jpeg';
import Semenihina from '../images/semenihina.jpg';
import Kvurt from '../images/kvurt.jpeg';
import Zashupa from '../images/zashupa.jpeg';
import Golisheva from '../images/golisheva.jpeg';
import Dunaev from '../images/dunaev.jpg';
import Vakulov from '../images/vakulov.jpg';
import Kolodin from '../images/kolodin.jpg';
import Chegodaeva from '../images/chegodaeva.jpg';
import Rahimov from '../images/rahimov.jpg';
import Scherbinin from '../images/scherbinin.jpg';
import Poliponov from '../images/poliponov.jpg';
import Nasedkin from '../images/nasedkin.jpg';
import Maytakov from '../images/maytakov.jpeg';

const Instructors = ()=> {
    window.scrollTo(0,0);
    const [popupActive, setPopupActive] = useState(false);
    const handleClick = (event)=>{
      
      setPopupActive(!popupActive);
    }

    const data = [
        {id:1, name: 'МЯГКОВ ЕВГЕНИЙ АНДРЕЕВИЧ', photo: Myagkov, href: 'https://arasia.ru/instruktor-po-gornym-lyzham-kp205/', category: '3', about: 'Призер Всероссийских соревнований по горнолыжному многоборью спасательных служб России. Судья Всероссийской категории по Альпинизму, скиальпинизму, фрирайду FWQ. Призёр Winter Cup Radio Monte Carlo 2023(Роза Хутор)'},
        {id:2, name:'МАЙТАКОВ СЕРГЕЙ ВЛАДИМИРОВИЧ', photo: Maytakov, href:'_blank', category:'2', about:'Мастер Спорта Международного Класса по горным лыжам. Многократный победитель и призёр этапов Кубка Европы. Победитель Чемпионата России в 2020 году. Входил в 30 лучших горнолыжников мира. Судья первой категории.'},
        {id:3, name:'СМОЛЯНИНОВ АЛЕКСЕЙ ВИТАЛЬЕВИЧ', photo: Smolyaninov, href:'https://arasia.ru/instruktor-po-gornym-lyzham-nv214/', category:'1', about:'КМС по горнолыжному спорту, победитель первенства России в параллельном слаломе, неоднократный победитель этапов РУС-Мастерс по горнолыжному спорту, победитель Кубка Сибири среди ветеранов, пятикратный призёр этапов Кубка Красной Поляны по горным лыжам 2023г. (GS)'},
        {id:4, name:'КВУРТ ЕВГЕНИЙ ВЛАДИМИРОВИЧ', photo: Kvurt, href:'https://arasia.ru/instruktor-po-gornym-lyzham-kp202/', category:'3', about:'Люблю внетрассовое катание, налыжах катаюсь давно, нравится передавать знания и видеть улыбки гостей от результата.'},
        {id:5, name:'РАХИМОВ ИЛЬДАР ЗАВДАТОВИЧ', photo: Rahimov, href:'https://arasia.ru/instruktor-po-gornym-lyzham-kp206/', category:'3', about:'Лучше гор могут быть только горы. А если ещё и едешь с горы на лыжах, любуясь пейзажами и отлично управляешь - это кайф, драйв, адреналин. Моя задача научить и влюбить в горные лыжи.'},
        {id:6, name:'ТУМАНОВ ЕВГЕНИЙ ИГОРЕВИЧ', photo: Tumanov, href:'https://arasia.ru/instruktor-po-gornym-lyzham-ms312/', category:'2', about:'КМС по горнолыжному спорту.'},
        {id:7, name:'ПОЛИПОНОВ ПАВЕЛ ВИКТОРОВИЧ', photo: Poliponov, href:'https://arasia.ru/instruktor-po-gornym-lyzham-kp320/', category:'3', about:'Опыт работы 10 лет. Подход к любому гостю - взрослому или ребёнку. Умею поставить на сноуборд или лыжи за 1 день.'},
        {id:8, name:'ЩЕРБИНИН ДЕНИС МИХАЙЛОВИЧ', photo: Scherbinin, href:'https://arasia.ru/instruktor-po-gornym-lyzham-kp318/' , category:'3', about:'Обучаю горным лыжам гостей курорта уже 10 лет. Тренирую начинающих, прогрессирующих, любой возраст, все направления: фрирайд, фристайл, карвинг. Предпочитаю свободный стиль катания, постановка техники. Получаю и дарю удовольствие от процесса и достигнутого результата.'},
        {id:9, name:'НАСЕДКИН ВЛАДИМИР ФЁДОРОВИЧ', photo: Nasedkin, href:'https://arasia.ru/instruktor-po-snoubordu-ms319/',category:'1', about:'Время проведённое в горах - лучшее. С удовольствием делюсь знаниями и опытом.'},
        {id:10, name:'ЗАШУПА НИКОЛАЙ АЛЕКСАНДРОВИЧ', photo: Zashupa, href:'https://arasia.ru/instruktor-po-gornym-lyzham-kp315/',category:'3', about:'Никогда не думал что буду кататься на горных лыжах. В итоге процесс оказался настолько увлекательным, что я решил стать инструктором. Люблю скорость, карвить, катать в свитче. Если вы за активный отдых, тогда вам ко мне!'},
        {id:11, name:'ГОЛЫШЕВА ЕЛЕНА ВЛАДИМИРОВНА', photo:Golisheva, href:'https://arasia.ru/instruktor-po-gornym-lyzham-kp314/',category:'3', about:'Горные лыжи - образ жизни. Горные лыжи это комплекс мероприятий. Работаю инструктором 8 лет. Люблю работать с детьми.'},
        {id:12, name:'ДУНАЕВ ВЯЧЕСЛАВ НИКОЛАЕВИЧ', photo:Dunaev, href:'https://arasia.ru/instruktor-po-gornym-lyzham-kp319/',category:'3', about:'С горными лыжами познакомился случайно в 1975 году, друг отдал путёвку в Терскол. Учился кататься ещё на деревянных лыжах. Ботинки были со шнурками. Через неделю спустился с Чегета. Снег, горы, воздух... мгновенно понял что лучше гор нету ничего лучше. Моё правило - нет плохого ученика, есть плохой учитель.'},
        {id:13, name:'ВАКУЛОВ АЛЬБЕРТ ВАЛЕРЬЕВИЧ', photo:Vakulov, href:'https://arasia.ru/instruktor-po-gornym-lyzham-kp317/',category:'3', about:'КМС по горнолыжному спорту. Дарю позитив, хорошие эмоции, результат гарантирую.'},
        {id:14, name:'КОЛОДИН АНДРЕЙ ВЛАДИМИРОВИЧ', photo: Kolodin, href:'https://arasia.ru/instruktor-po-gornym-lyzham-kp313/',category:'3', about:'Люблю снежные склоны, экстрим. Катаюсь на горных лыжах больше 20 лет, готов поделиться своими навыками и знаниями с вами.'},
        {id:15, name:'ЧЕГОДАЕВА ЕКАТЕРИНА ВЛАДИМИРОВНА', photo: Chegodaeva, href:'https://arasia.ru/instruktor-po-gornym-lyzham-kp209/',category:'3', about:'Детский инструктор с 2015 года. Провожу детский дневной лагерь с понедельника по пятницу, за 5 дней дети научатся кататься на лыжах, весело проведут время.'},
        {id:16, name:'СЕМЕНИХИНА АЛЛА АЛЕКСАНДРОВНА', photo: Semenihina, href:'https://arasia.ru/instruktor-po-gornym-lyzham-kp316/',category:'3', about:'Люблю работать с детьми, на лыжах катаюсь очень давно, нравится передавать знания и видеть результат.'},
    ]

    let res = data.map(function(item){
        return <div className='instructor__card'>
        <div className='instructor__profile'>
        <a  href={item.href} target='_blank'>
            <img className='instructor__img' src={item.photo}></img>
            <div className='instructor__name'>{item.name}<br/>Инструктор {item.category}-й категории</div>
        </a>
        </div>
        <div className='instructor__about'>
            {item.about}
             {/* <PopupButton onClick={handleClick} /> */}
        </div>
    </div>
    })
    
    return (
        <>
        <Popup active={popupActive} setActive={setPopupActive} children={<Popupform/>}/>
        <section className='instructors'>
            <h3 className='instructors'>НАША КОМАНДА В КРАСНОЙ ПОЛЯНЕ</h3>
            {res}
        </section>
            <Whatsapp/>
        </>
    )
}
export default Instructors