import './important.css';

const Important = ()=>{
    return(
        <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="red" fillOpacity="1" d="M0,128L48,154.7C96,181,192,235,288,229.3C384,224,480,160,576,149.3C672,139,768,181,864,213.3C960,245,1056,267,1152,256C1248,245,1344,203,1392,181.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
        <section className='important'>
        <div className='important'>
        <h1 className='important__why'>ВАЖНО</h1>
        <h3 className='important__why' >НЕ РИСКУЙТЕ ИСПОРТИТЬ СВОЙ ОТДЫХ ОБРАЩАЯСЬ К ЗАЗЫВАЛАМ С УЛИЦЫ!</h3>
        <h3 className='important__why'>МНИМАЯ ЭКОНОМИЯ МОЖЕТ ОБЕРНУТЬСЯ СЕРЬЁЗНЫМИ ПОСЛЕДСТВИЯМИ.</h3>
        <h3 className='important__why'>ТРЕБУЙТЕ УДОСТОВЕРЕНИЕ ИНСТРУКТОРА И АККРЕДИТАЦИЮ НА КУРОРТЕ.</h3>
        <h3 className='important__why'>ПОМНИТЕ, ГОРНЫЕ ЛЫЖИ - ЭКСТРЕМАЛЬНЫЙ ВИД СПОРТА!</h3>
        </div>
        <svg style={{marginBottom: '-6px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="white" fillOpacity="1" d="M0,128L48,154.7C96,181,192,235,288,229.3C384,224,480,160,576,149.3C672,139,768,181,864,213.3C960,245,1056,267,1152,256C1248,245,1344,203,1392,181.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
      
        </section>
        </>

    )
}
export default Important